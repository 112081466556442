import React, {useState, useEffect} from 'react';
import './ResetPassword.scss';

import {navigate} from 'gatsby';
import {useQueryParam, StringParam} from "use-query-params";
import {resetPassword} from '@repository/UserRepository';

function ResetPassword() {
  const [token, setToken] = useQueryParam("token", StringParam);
  const [inputs, setInputs] = useState({
    token: token,
    password: '',
    password_confirmation: '',
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleOnInputsChanged = (e) => {
    let label = e.target.name;
    let value = e.target.value;
    setInputs((currentValue) => (
      {
        ...currentValue,
        [label]: value,
      }
    ));
  }

  const handleResetPassword = () => {
    setIsLoading(true);
    setErrors({});
    resetPassword(inputs)
      .then((res) => {
        setIsLoading(false);
        navigate('/reset-password-success');
      })
      .catch((err) => {
        if (err.response.data.status_code === 422) {
          setErrors(err.response.data.errors);
          console.log(err.response.data.errors);
        } else {
          setErrors({
            password: [err.response.data.message],
          })
          console.log(err.response);
        }
        setIsLoading(false);
      })
  }

  const hasValidInputs = () => {
    if (
      !inputs.token ||
      !inputs.password ||
      !inputs.password_confirmation
    ) {
      return false;
    }
    return true;
  }

  return (
    <div id="reset-password" className="container py-5">
      <div className="row justify-content-center">
        <div className="col-6 d-flex flex-column">
          <div className="dosis-title-1 mx-auto">
            Reset Password
          </div>
          <div className="divider bg-abalone-gray align-self-center my-4" />
          <div className="asap-body text-coal-black mb-3">
            Enter your new password. Make sure it is strong enough to make your account secured.
          </div>
          <div className={'form-group d-flex flex-column-reverse mb-2'}>
            <input
              id="new-password"
              className="form-control"
              type="password"
              placeholder="Enter your password"
              name="password"
              value={inputs.password}
              onChange={handleOnInputsChanged}
              onKeyPress={event => {
                if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                  handleResetPassword();
                }
              }}
              required
            />
            <label htmlFor='new-password' className='input-label'>
              New Password
            </label>
          </div>
          <div className={`asap-caption text-harbor-gray ${errors.password ? 'mb-1' : 'mb-3'}`}>
            Password must contain at least <b>eight characters</b> categories among the following: Uppercase characters (A-Z) Lowercase characters (a-z)
          </div>
          {errors.password && (
            <div className="asap-caption-1 text-apple-red mb-3">{errors.password[0]}</div>
          )}
          <div className={'form-group d-flex flex-column-reverse mb-4'}>
            <input
              id="re-new-password"
              className="form-control"
              type="password"
              placeholder="Re-enter your password"
              name="password_confirmation"
              value={inputs.password_confirmation}
              onChange={handleOnInputsChanged}
              onKeyPress={event => {
                if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                  handleResetPassword();
                }
              }}
              required
            />
            <label htmlFor='re-new-password' className='input-label'>
              Re-enter new password
            </label>
          </div>
          <button
            className="button-md button-primary"
            disabled={isLoading || !hasValidInputs()}
            onClick={handleResetPassword}>
            {isLoading ? <div className="mdi mdi-loading mdi-spin" /> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
